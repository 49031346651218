import React from 'react'
/*import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@theme-ui/mdx'
import components from '@components/Mdx'*/
import ReactHtmlParser from 'react-html-parser';

export const PostBody = ({ body }) => {
    const getContent = () => {
        let content = body.replace(/(?:\r\n|\r|\n)/g, '');
        content = content.replace(/ aria-level="1"/g, '');
        content = content.replace(/ style="font-weight: 400;"/g, '');
        content = content.replace(/ padding-left:40px/g, '');
        //content = content.replace(/src="https:\/\/www.youtube.com\/embed/g, "data-src=\"https://www.youtube.com/embed");
        content = content.replace(/<td> *<p>/g, "<td>");
        content = content.replace(/<\/p> *<\/td>/g, "</td>");
        //content = content.replace(/<a href="https:\/\/www.pensiontimes.co.uk/g, '<a href="');
        return content;
    }

    const transform = (node, index) => {

    }

  return (
      <div>{ReactHtmlParser(getContent(), {transform})}</div>
    /*<MDXProvider components={components}>
      <MDXRenderer>{body}</MDXRenderer>
    </MDXProvider>*/
  )
}
