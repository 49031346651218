import { graphql } from 'gatsby'
import PostPage from '../containers/Post'

export default PostPage

export const pageQuery = graphql`
  query PostPageQuery(
    $id: String!
    $previousId: String
    $nextId: String
    $categoryId: String
    $imageQuality: Int!
  ) {
    post: datoCmsArticle(originalId: { eq: $id }) {
      ...ArticleInformation
      ...ArticleThumbnailRegular
    }

    # Related posts based on tags and category
    

    # Related posts based on tags only
    

    # Related posts based on category only
    categoryPosts: allDatoCmsArticle(
      filter: {
        category: {elemMatch: {originalId: {eq: $categoryId}}}
        originalId: { ne: $id }
      }
      sort: { fields: [publishedAt], order: DESC }
      limit: 6
    ) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailRegular
      }
    }

    previous: datoCmsArticle(originalId: { eq: $previousId }) {
      id: originalId
      slug
      heading
    }

    next: datoCmsArticle(originalId: { eq: $nextId }) {
      id: originalId
      slug
      heading
    }
  }
`
